import {getWebInstrumentations, initializeFaro} from '@grafana/faro-web-sdk';
import {TracingInstrumentation} from '@grafana/faro-web-tracing';

export function faroInitializer(): Function {
  return async () => {
    initializeFaro({
      url: 'https://faro-collector-prod-sa-east-1.grafana.net/collect/6e3e192ebae1c1fd3e7f976e7ad7ac37',
      app: {
        name: 'app-frontend-ma',
        version: '1.0.0',
        environment: 'production',
      },
      instrumentations: [
        ...getWebInstrumentations({
          captureConsole: true,
          captureConsoleDisabledLevels: [],
        }),
        // Initialization of the tracing package.
        // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
        new TracingInstrumentation(),
      ],
    });
  };
}